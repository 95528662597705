import React from 'react';

import BaseIcon from '../../utils/BaseIcon';

const NotSoGoodGraphicIcon = props => {
  const {size = 48, ...otherProps} = props;
  const iconFill = 'currentColor';

  return (
    <BaseIcon size={size} {...otherProps} data-mesh-component= 'NOT-SO-GOOD-GRAPHIC-ICON'>
      <g fill={iconFill} fillRule="nonzero"><path d="M18.048 14.5a.5.5 0 010 1 6.25 6.25 0 00-5.6 3.472.5.5 0 11-.896-.444 7.25 7.25 0 016.496-4.028zM15.75 9.625l.11.007a.875.875 0 11-.22 0l.11-.007zm-8.25 0l.11.007a.875.875 0 11-.22 0l.11-.007z"/><path d="M12 .25C5.51.25.25 5.51.25 12S5.51 23.75 12 23.75 23.75 18.49 23.75 12 18.49.25 12 .25zm0 1c5.937 0 10.75 4.813 10.75 10.75S17.937 22.75 12 22.75 1.25 17.937 1.25 12 6.063 1.25 12 1.25z"/></g>
    </BaseIcon>
  );
};

NotSoGoodGraphicIcon.displayName = 'NotSoGoodGraphicIcon';

NotSoGoodGraphicIcon.displayName = 'NotSoGoodGraphicIcon';
NotSoGoodGraphicIcon.iconName = 'NotSoGood';

export default NotSoGoodGraphicIcon;
